export function propertyPrice(
  property,
  considerPriceUponRequest = true,
  requestString = 'Trattativa riservata'
) {
  if (property.building.category.name === 'Cantieri') {
    return (property.transaction.price_upon_request &&
      considerPriceUponRequest) ||
      !property.transaction.price_min
      ? requestString
      : `da € ${this.$utilities.formatNumber(property.transaction.price_min)}`
  }

  return property.transaction.price_upon_request && considerPriceUponRequest
    ? requestString
    : property.transaction.price
    ? `€ ${this.$utilities.formatNumber(property.transaction.price)}`
    : `n.d.`
}

export function highlightSearchedText(text, searchText) {
  if (!searchText || !text) {
    return
  }

  const regex = new RegExp(searchText.replace(/[^a-z0-9 'àèéòù]/gi, ''), 'gi')

  return text.replace(
    regex,
    '<span class="ucsc-autocomplete__result--emphasized">$&</span>'
  )
}
