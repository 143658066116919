const enabledPrivateRoutes = [
  'area-riservata-login',
  'area-riservata-privacy',
  'area-riservata-registrati',
  'area-riservata-registrazione-verifica-id-token',
  'area-riservata-reset',
  'area-riservata-reset-token',
  'area-riservata-registrazione-veloce-modifica-password',
]

const openSubnavRoutesRegex = [
  /\/vendo/,
  /\/vendo\/vendere-casa-*/,
  /\/valuta-casa/,
  /\/servizi-documenti-casa\/documenti-compravendita/,
  /\/servizi-documenti-casa\/patrimonio-immobiliare/,
  /\/news\/come-fare\/come-vendere-casa-rapidamente\//,
  /\/news\/come-fare\/documenti-vendita-casa\//,
]

const unitsKeys = {
  m2: 'm<sup>2</sup>',
  'm²': 'm<sup>2</sup>',
  mq: 'm<sup>2</sup>',
  EUR: '€',
  '&euro;': '€',
}

const privateGardenKeys = {
  unspecified: 'n.d.',
  present: 'Sì',
  absent: 'No',
}

const kitchenKeys = {
  habitable: 'cucina abitabile',
  kitchen_corner: 'angolo cottura',
  kitchenette: 'cucinino',
  semi_habitable: 'cucina semi abitabile',
  dining_area: 'tinello',
  open_kitchen: 'cucina a vista',
}

const realEstatesPortals = {
  'immobiliare.it': 'immobiliare.it',
  'Idealista.it': 'idealista.it/casa.it',
  'Trovacasa.it': 'trovacasa.it',
  'UniCredit - Immobili': 'unicreditsubitocasa.it',
}

export {
  kitchenKeys,
  openSubnavRoutesRegex,
  privateGardenKeys,
  realEstatesPortals,
  enabledPrivateRoutes,
  unitsKeys,
}
